import React from "react";
import { Layout, Button } from "antd";
import { navigate } from "gatsby";
import { DollarOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { TabBar } from "antd-mobile";

import { mobileMenu, URLS } from "@helpers/const";

import { isLoggedIn } from "@services/auth";
import withLocation from "@helpers/hoc/withLocation";

const AntFooter = Layout.Footer;

type WithThisUser<T = {}> = T & { children?: React.ReactNode };

type FooterProps = WithThisUser<{
  userDetails: {
    name?: string;
    avatarUrl?: string;
  };
  location: object;
}>;

const Footer: React.FC<FooterProps> = ({ location }: FooterProps) => {
  const [value, setValue] = React.useState(location.pathname);

  return (
    <FooterWrapper>
      <AntFooter
        className="fixed bottom-0 z-10 w-full p-0 text-center"
        style={{
          transition: "bottom 0.2s",
          boxShadow:
            "rgb(136 136 136) 0px 25px 1px, rgb(136 136 136) 0px 4px 20px",
        }}
      >
        <div className="flex justify-center items-center">
          <Button
            shape="circle"
            className="bg-primary-100 text-white text-2xl w-16 h-16" // Increased size using Tailwind classes
            style={{
              position: "absolute",
              bottom: "100%",
              transform: "translateY(50%)",
            }} // Positioning the button
            onClick={() => {
              navigate("/");
            }}
          >
            <DollarOutlined />
          </Button>
        </div>
        <TabBar
          safeArea
          className="text-ds-blue-100 p-2"
          activeKey={value}
          onChange={url => {
            setValue(url);
            navigate(url);
          }}
        >
          {mobileMenu.map(item => {
            if (item.url === URLS.login && isLoggedIn()) {
              return (
                <TabBar.Item
                  key={item.logedInURL}
                  icon={item.Icon}
                  title={item.logedInText}
                />
              );
            }
            return (
              <TabBar.Item key={item.url} icon={item.Icon} title={item.text} />
            );
          })}
        </TabBar>
      </AntFooter>
    </FooterWrapper>
  );
};

export default withLocation(Footer);

const FooterWrapper = styled.div`
  /* && {
    margin: 0 auto;
    padding-left: 10%;
    padding-right: 10%;
    height: 100%;
  }

  .welcomeRow {
    padding: 10px;
  } */
`;
