import React from "react";
import { Row, Statistic, Button } from "antd";
import { PageHeader as AntPageHeader } from "@ant-design/pro-layout";
import {
  EyeOutlined,
  SettingOutlined,
  EditOutlined,
  PlusOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
type Props = {
  statisticTitle: {
    first?: string;
    second?: string;
    third?: string;
    firstAmt?: number;
    secondAmt?: number;
    thirdAmt?: number;
  };
  handleSetting: () => void;
  handleEditBalance?: () => void;
  title: string;
  prefix?: string;
};

export const AccountsHeader: React.FC<Props> = ({
  statisticTitle,
  handleSetting,
  handleEditBalance,
  title,
  prefix = "MYR",
}: Props) => {
  return (
    <AccountsHeaderWrapper>
      <AntPageHeader
        onBack={() => window.history.back()}
        title={title}
        subTitle={
          <div>
            <EyeOutlined className="text-lg cursor-pointer" />
            <SettingOutlined
              className="ml-2 text-lg cursor-pointer"
              onClick={() => handleSetting()}
            />
          </div>
        }
      >
        <Row>
          {statisticTitle.first && (
            <>
              <Statistic
                className="!mr-4"
                title={
                  <div
                    className="cursor-pointer"
                    onClick={() => handleEditBalance()}
                  >
                    {statisticTitle.first}
                    <EditOutlined className="ml-2 text-sm !inline-grid cursor-pointer" />
                  </div>
                }
                prefix={prefix}
                value={
                  statisticTitle.firstAmt
                    ? statisticTitle.firstAmt.toFixed(2)
                    : 0
                }
              />
            </>
          )}
          {statisticTitle.second && (
            <Statistic
              className="!mr-4"
              title={statisticTitle.second}
              prefix={prefix}
              value={
                statisticTitle.secondAmt
                  ? statisticTitle.secondAmt.toFixed(2)
                  : 0
              }
            />
          )}
          {statisticTitle.third && (
            <Statistic
              className="!mr-4"
              title={statisticTitle.third}
              prefix={prefix}
              value={statisticTitle.thirdAmt ? statisticTitle.thirdAmt : 0}
            />
          )}
        </Row>
      </AntPageHeader>
    </AccountsHeaderWrapper>
  );
};

const AccountsHeaderWrapper = styled.div``;
