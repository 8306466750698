import React from "react";
import { useQuery } from "@apollo/client";
import { queries } from "@helpers/queryStrings";
import { getUser } from "@services/auth";

export const withQueryFormDetails =
  (ComponentToWrap: any) =>
  (props: any): Promise<void> => {
    const user = getUser();

    const { data, loading, refetch } = useQuery(queries.formDetails, {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
      variables: {
        // transactionTagsFilters: {
        //   user: { id: { eq: Number(id) } },
        // },
        accountFilters: {
          user: { id: { eq: Number(user?.id) } },
        },
        pagination: {
          pageSize: 100,
        },
      },
    });

    return (
      <ComponentToWrap
        {...props}
        formDetailsData={data}
        formDetailsLoading={loading}
        formDetailsRefetch={refetch}
      />
    );
  };
