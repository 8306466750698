import React from "react";
import { navigate } from "gatsby";
import { isLoggedIn } from "@services/auth";
import { URLS, paths } from "@helpers/const";

// import "../../../__mock__/matchMedia.mock";

type Props = {
  location?: {
    pathname?: string;
  };
  component?: any;
  path?: any;
};

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  location,
  ...rest
}: Props) => {
  React.useEffect(() => {
    if (location?.pathname !== paths.access && !isLoggedIn()) {
      navigate(URLS.login, { replace: true });
    }
  }, [location]);

  if (location?.pathname === paths.access) {
    return <Component location={location} {...rest} />;
  }

  return isLoggedIn() ? <Component location={location} {...rest} /> : null;
};

export default PrivateRoute;
