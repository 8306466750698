import React from "react";
import { Avatar, Button } from "antd";
import { navigate } from "gatsby";
import { getFirstLetter } from "@helpers/tools";
import { URLS, appDetails } from "@helpers/const";
import styled from "styled-components";
import SEO from "@components/common/seo";
import { getUser, logout } from "@services/auth";

const Profile = (): void => {
  const { username } = getUser();

  return (
    <ProfileWrapper>
      <SEO title="Profile" />
      <Avatar size={100}>{getFirstLetter(username)}</Avatar>
      <div className="my-4">{username}</div>
      <Button onClick={() => logout(() => navigate(URLS.home))}>Logout</Button>
      <Version>Version {appDetails.version}</Version>
    </ProfileWrapper>
  );
};

export default Profile;

const ProfileWrapper = styled.div`
  && {
    margin: 0 auto;
  }
  padding: 30px;
  text-align: center;
`;

const Version = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: #999;
`;
