import React from "react";
import { ApolloProvider } from "@apollo/client";
import { appDetails } from "@helpers/const";

// import { Provider as ReduxProvider } from "react-redux"

import { apollo } from "./graphql/client";
// import { createReduxStore } from "./redux/store"

import { TransactionProvider } from "@helpers/context/transaction-context";

// Create an AuthProvider component
const AuthProvider = ({ children }) => {
  React.useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem(`${appDetails.name}_token`);
      if (token) {
        apollo.reFetchObservableQueries();
      }
    };

    // Check auth status periodically
    const interval = setInterval(checkAuth, 60000);
    return () => clearInterval(interval);
  }, []);

  return children;
};

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={apollo}>
    <AuthProvider>
      <TransactionProvider>{element}</TransactionProvider>
    </AuthProvider>
  </ApolloProvider>
);

// export default ({ element }) => (
//   <ReduxProvider store={createReduxStore()}>{element}</ReduxProvider>
// )
