import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Layout as AntLayout, FloatButton } from "antd";
import { SwapOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import { useTransactionContext } from "@helpers/context/transaction-context";
import { CreateTransactionModal } from "@components/common/createTransactionModal";
import { CreateInterTransactionModal } from "@components/common/createInterTransactionModal";
import { getUser } from "@services/auth";
import Footer from "./footer";
import withLocation from "@helpers/hoc/withLocation";
import { withQueryFormDetails } from "@helpers/hoc/withQueries";
import { URLS } from "@helpers/const";
import { withMutateTransact } from "@helpers/hoc/withMutations";

const { Content } = AntLayout;

type Props = {
  location?: {
    pathname: string;
  };
  formDetailsData?: any;
  createTransaction: Function;
};

const Layout: React.FC<Props> = ({
  children,
  location,
  formDetailsData,
  createTransaction,
}) => {
  const {
    setIsCreateTransactModalVisible,
    setIsCreateInterTransactModalVisible,
    isCreateTransactModalVisible,
    isCreateInterTransactModalVisible,
  } = useTransactionContext();

  const user = getUser();

  // Get accountID from URL if we're on an account page
  const accountID = location?.pathname?.includes("/accounts/")
    ? location.pathname.split("/accounts/")[1]
    : undefined;

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <>
          <AntLayout style={{ overflow: "hidden" }}>
            <Content style={{ paddingBottom: "80px" }}>
              <main>{children}</main>
            </Content>

            {!(location.pathname === URLS.login) && (
              <>
                <FloatButton.Group
                  shape="circle"
                  style={{
                    insetBlockEnd: 80,
                  }}
                >
                  <FloatButton
                    onClick={() => setIsCreateTransactModalVisible(true)}
                    type="primary"
                    icon={<MoneyCollectOutlined />}
                  />
                  <FloatButton
                    onClick={() => setIsCreateInterTransactModalVisible(true)}
                    icon={<SwapOutlined />}
                  />
                </FloatButton.Group>

                <CreateTransactionModal
                  isModalVisible={isCreateTransactModalVisible}
                  setIsModalVisible={setIsCreateTransactModalVisible}
                  formDetailsData={formDetailsData}
                  accountID={accountID}
                  onHandleFormSubmit={(values, form) => {
                    values.user = user?.id;
                    values.amount = Number(values.amount);
                    createTransaction({
                      variables: {
                        data: values,
                      },
                    });
                    form.resetFields();
                    setIsCreateTransactModalVisible(false);
                  }}
                />

                <CreateInterTransactionModal
                  isModalVisible={isCreateInterTransactModalVisible}
                  setIsModalVisible={setIsCreateInterTransactModalVisible}
                  formDetailsData={formDetailsData}
                  accountID={accountID}
                  onHandleFormSubmit={(values, form) => {
                    createTransaction({
                      variables: {
                        data: {
                          amount: Number(values.transfer_amount),
                          transaction_type: 2,
                          account: values.from_account,
                          date_time: values.date_time,
                          transaction_tag: 3,
                          user: user?.id,
                        },
                      },
                    });
                    createTransaction({
                      variables: {
                        data: {
                          amount: Number(values.transfer_amount),
                          transaction_type: 1,
                          account: values.to_account,
                          date_time: values.date_time,
                          transaction_tag: 3,
                          user: user?.id,
                        },
                      },
                    });
                    form.resetFields();
                    setIsCreateInterTransactModalVisible(false);
                  }}
                />
              </>
            )}
            {!(location.pathname === URLS.login) && <Footer />}
          </AntLayout>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  formDetailsData: PropTypes.object,
  createTransaction: PropTypes.func.isRequired,
};

export default withLocation(withQueryFormDetails(withMutateTransact(Layout)));
