import React from "react";
import { Avatar, Button, List, Skeleton, Divider, Form, Modal } from "antd";
import { PageHeader } from "@ant-design/pro-layout";

import {
  PlusCircleOutlined,
  DeleteOutlined,
  DeleteFilled,
} from "@ant-design/icons";

import {
  getFirstLetter,
  formatISODate,
  capitalizeFirstLetter,
} from "@helpers/tools";

import { withMutateRecurrent } from "@helpers/hoc/withMutations";
import { withQueryFormDetails } from "@helpers/hoc/withQueries";
import { EditRecurrentModal } from "@components/common/editRecurrentModal";
import { CreateRecurrentModal } from "@components/common/createRecurrentModal";

import { useQuery } from "@apollo/client";
import { queries } from "@helpers/queryStrings";

import styled from "styled-components";
import SEO from "@components/common/seo";
import { getUser } from "@services/auth";
import withLocation from "@helpers/hoc/withLocation";
import dayjs from "dayjs";

type Props = {
  search?: {
    search: string;
  };
  location: object;
  formDetailsData: object;
  createRecurrentData: object;
  createRecurrent: Function;
  deleteRecurrent: Function;
  updateRecurrent: Function;
};

const Recurrent = ({
  createRecurrentData,
  createRecurrent,
  deleteRecurrent,
  updateRecurrent,
  formDetailsData,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isEditRecurrentModalVis, setIsEditRecurrentModalVis] =
    React.useState(false);

  const [editID, setEditID] = React.useState();
  const [pageSize, setPageSize] = React.useState(10);

  const user = getUser();
  const [form] = Form.useForm();

  const {
    data: recurrentsData,
    loading: recurrentsLoading,
    refetch: recurrentsRefetch,
  } = useQuery(queries.recurrents, {
    variables: {
      filters: {
        user: { id: { eq: Number(user?.id) } },
      },
      pagination: {
        pageSize,
      },
    },
  });

  const loadMore = !recurrentsLoading ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button onClick={() => setPageSize(pageSize + 5)}>load more</Button>
    </div>
  ) : null;

  React.useEffect(() => {
    recurrentsRefetch();
  }, [createRecurrentData]);
  return (
    <RecurrentWrapper>
      <SEO title="Recurrent" />
      <PageHeader
        onBack={() => window.history.back()}
        title={"Recurrent Transactions"}
        subTitle={
          <div>
            <PlusCircleOutlined
              className="!text-lg cursor-pointer"
              onClick={() => setIsModalVisible(true)}
            />
          </div>
        }
      ></PageHeader>
      <Divider orientation="left">All Records</Divider>
      <div className="mx-5">
        <List
          className="demo-loadmore-list"
          loading={recurrentsLoading}
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={recurrentsData?.recurrents?.data}
          renderItem={item => (
            <List.Item
              actions={[
                <a
                  key="list-loadmore-edit"
                  onClick={() => {
                    console.log(item);
                    setEditID(Number(item.id));
                    setIsEditRecurrentModalVis(true);
                    form.setFieldsValue({
                      transaction_type:
                        item?.attributes?.transaction_type?.data?.id,
                      account: item?.attributes?.account?.data?.id,
                      transaction_tag:
                        item?.attributes?.transaction_tag?.data?.id,
                      publish_date: dayjs(item?.attributes?.publish_date),
                      amount: Number(item?.attributes?.amount),
                      remark: item?.attributes?.remark,
                      duration: item?.attributes?.duration,
                    });
                  }}
                >
                  Edit
                </a>,
                <a
                  key="list-loadmore-edit"
                  onClick={() => {
                    Modal.confirm({
                      title: "Confirm to Delete this Record?",
                      icon: <DeleteFilled />,
                      okType: "danger",
                      okText: (
                        <>
                          <DeleteOutlined /> Yes
                        </>
                      ),
                      onOk() {
                        console.log("OK");
                        deleteRecurrent({
                          variables: { id: item.id },
                        });
                      },
                      onCancel() {
                        console.log("Cancel");
                      },
                    });
                  }}
                >
                  Delete
                </a>,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar>
                      {getFirstLetter(
                        item?.attributes?.account?.data?.attributes?.name
                      )}
                    </Avatar>
                  }
                  title={
                    <div>
                      {capitalizeFirstLetter(
                        item?.attributes?.account?.data?.attributes?.name
                      )}
                    </div>
                  }
                  description={
                    <div>
                      {formatISODate(item?.attributes?.publish_date)}{" "}
                      {capitalizeFirstLetter(
                        item?.attributes?.transaction_type?.data?.attributes
                          ?.name
                      )}
                    </div>
                  }
                />
                <div>
                  {item?.attributes?.amount} {" / "}
                  {capitalizeFirstLetter(item?.attributes?.duration)}
                </div>
              </Skeleton>
            </List.Item>
          )}
        />
      </div>
      <CreateRecurrentModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        formDetailsData={formDetailsData}
        onHandleFormSubmit={(values, form) => {
          values.user = user?.id;
          values.amount = Number(values.amount);

          createRecurrent({
            variables: {
              data: values,
            },
          });
          form.resetFields();
          setIsModalVisible(false);
        }}
      />
      <EditRecurrentModal
        formDetailsData={formDetailsData}
        updateRecurrent={updateRecurrent}
        Form={Form}
        form={form}
        setModalVis={setIsEditRecurrentModalVis}
        modalVis={isEditRecurrentModalVis}
        editID={editID}
        onHandleFormSubmit={values => {
          values.amount = Number(values.amount);
          updateRecurrent({
            variables: {
              id: editID,
              data: values,
            },
          });
          setIsEditRecurrentModalVis(false);
        }}
      />
    </RecurrentWrapper>
  );
};

export default withQueryFormDetails(
  withMutateRecurrent(withLocation(Recurrent))
);

const RecurrentWrapper = styled.div`
  && {
    margin: 0 auto;
  }
  .ant-list-item-meta-title {
    margin-top: 0;
  }
  .ant-page-header .ant-page-header-heading-sub-title {
    line-height: 100%;
  }
`;
