import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { message } from "antd";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import { appDetails } from "@helpers/const";

import SEO from "@components/common/seo";
import LoginForm from "@components/common/loginForm";

import { handleLogin, isLoggedIn } from "@services/auth";
import { mutation } from "@helpers/queryStrings";
import { URLS, messages } from "@helpers/const";
import withLocation from "@helpers/hoc/withLocation";

import logo from "@images/logos/logo.svg";

type SubmitLoginProps = {
  identifier: String;
  password: String;
};
type AccessProps = {
  search?: {
    auth: string;
    token: string;
    provider: string;
  };
  location: object;
};

const Access: React.FC = ({ search: { auth } }: AccessProps) => {
  const isSelection = auth ? auth : "login";
  const [messageState, setMessageState] = useState(true);

  const [login, { loading: loginLoading }] = useMutation(mutation.login, {
    fetchPolicy: "network-only",
    awaitRefetchQueries: true,
    onCompleted: (res: object): void => {
      if (res) {
        handleLogin(res);
        navigate(URLS.home);
      }
    },
    onError: (err: object): void => {
      if (err) {
        if (messageState) {
          setMessageState(false);
          message.warning(messages.errors.login, () => {
            setMessageState(true);
          });
        }
      }
    },
  });

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(URLS.home);
    }
  }, []);

  return (
    <>
      <SEO title="Login" />
      <div className="p-14">
        <Logo />
        <LoginForm
          loading={loginLoading}
          isSelection={isSelection}
          submitLogin={(values: SubmitLoginProps) =>
            login({
              variables: {
                input: {
                  identifier: values.email,
                  password: values.password,
                },
              },
            })
          }
        />
        <Version>Version {appDetails.version}</Version>
      </div>
    </>
  );
};

export default withLocation(Access);

const Logo = styled.div`
  width: 123px;
  height: 166px;
  background: url(${logo});
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 50px auto;
`;

const Version = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: #999;
  text-align: center;
`;
