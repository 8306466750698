import { appDetails, URLS } from "@helpers/const";
import { apollo } from "@helpers/states-providers/graphql/client";
export const isBrowser: boolean = () => typeof window !== "undefined";

const authTokenKey = `${appDetails.name}_token`;

export const getToken: object = () =>
  isBrowser() && JSON.parse(localStorage.getItem(authTokenKey))?.token;
export const getUser: object = () =>
  isBrowser() && JSON.parse(localStorage.getItem(authTokenKey));

const setDetails = (user: object) =>
  localStorage.setItem(authTokenKey, JSON.stringify(user));

export const handleLogin: void = (res: object) => {
  const {
    jwt,
    user: { id, username },
  } = res.login ? res.login : res.register ? res.register : res.ssoLogin;

  if (jwt) {
    setDetails({
      username,
      id,
      token: jwt,
    });
  }
};

// Add this function to check if token is expired
const isTokenExpired = (token: string): boolean => {
  try {
    const tokenData = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = tokenData.exp * 1000;
    // Add 5 second buffer to prevent edge cases
    return Date.now() >= expirationTime - 5000;
  } catch (error) {
    console.error("Token validation error:", error);
    return true;
  }
};

// Modify the isLoggedIn function
export const isLoggedIn = (): boolean => {
  try {
    const token = getToken();
    if (!token) return false;

    if (isTokenExpired(token)) {
      // Clear everything synchronously
      localStorage.removeItem(authTokenKey);
      apollo.clearStore().then(() => {
        window.location.href = URLS.login;
      });
      return false;
    }
    return true;
  } catch (error) {
    console.error("Auth check error:", error);
    return false;
  }
};

export const logout = async (callback?: Function) => {
  try {
    await apollo.clearStore(); // This will clear cache and local state
    localStorage.removeItem(authTokenKey);
    if (callback) callback();
  } catch (error) {
    console.error("Logout error:", error);
    // Force clear even if there's an error
    localStorage.removeItem(authTokenKey);
    if (callback) callback();
  }
};
