import React from "react";
import { Button, Row, Statistic } from "antd";
import { PageHeader as AntPageHeader } from "@ant-design/pro-layout";

import {
  EyeOutlined,
  PlusOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

type Props = {
  handleCreateTransaction: Function;
  handleCreateInterTransaction: Function;
  statisticTitle: object;
  title: string;
  prefix: string;
};

export const PageHeader: React.FC<Props> = ({
  handleCreateTransaction,
  handleCreateInterTransaction,
  statisticTitle,
  prefix = "MYR",
  title,
}: Props) => {
  return (
    <PageHeaderWrapper>
      <div>
        <AntPageHeader
          // className="fixed top-0 left-0 w-full z-100 bg-white" // Added Tailwind classes
          title={title}
          subTitle={
            <div>
              <EyeOutlined className="!text-lg" />
            </div>
          }
          // extra={[
          //   <Button
          //     key="1"
          //     type="primary"
          //     icon={<PlusOutlined className="!inline-grid" />}
          //     shape="circle"
          //     onClick={() => handleCreateTransaction()}
          //   ></Button>,
          //   <Button
          //     key="2"
          //     icon={<InteractionOutlined className="!inline-grid" />}
          //     shape="circle"
          //     onClick={() => handleCreateInterTransaction()}
          //   ></Button>,
          // ]}
        >
          <Row>
            <Statistic
              title={statisticTitle.first}
              prefix={prefix}
              value={
                statisticTitle.firstAmt ? statisticTitle.firstAmt.toFixed(2) : 0
              }
              className="!mr-4"
            />
            <Statistic
              title={statisticTitle.second}
              prefix={prefix}
              value={
                statisticTitle.secondAmt
                  ? statisticTitle.secondAmt.toFixed(2)
                  : 0
              }
              className="!mr-4"
            />
            <Statistic
              title={statisticTitle.third}
              prefix={prefix}
              value={
                statisticTitle.thirdAmt ? statisticTitle.thirdAmt.toFixed(2) : 0
              }
              className="!mr-4"
            />
          </Row>
        </AntPageHeader>
      </div>
    </PageHeaderWrapper>
  );
};

const PageHeaderWrapper = styled.div``;
