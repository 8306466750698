import React, { useState, useRef } from "react";
import {
  Button,
  Divider,
  Collapse,
  List,
  Skeleton,
  Avatar,
  Form,
  Radio,
  Tour,
} from "antd";
import { URLS } from "@helpers/const";
import { Tabs } from "antd-mobile";

import { navigate } from "gatsby";

import { PlusCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import SEO from "@components/common/seo";
import { CreateAccountModal } from "@components/common/createAccountModal";
import { CreateTransactionModal } from "@components/common/createTransactionModal";
import { CreateInterTransactionModal } from "@components/common/createInterTransactionModal";
import { PageHeader } from "@components/common/pageHeader";
import { queries } from "@helpers/queryStrings";
import { getFirstLetter } from "@helpers/tools";
import {
  withMutateTransact,
  withMutateAccount,
} from "@helpers/hoc/withMutations";
import { withQueryFormDetails } from "@helpers/hoc/withQueries";
import { withMutateTransactionTag } from "@helpers/hoc/withMutations";

import { getUser } from "@services/auth";
import withLocation from "@helpers/hoc/withLocation";

type Props = {
  search?: {
    search: string;
  };
  location: object;
  formDetailsData: object;
  createTransaction: Function;
  createAccount: Function;
  createTransactionTag: Function;
  createTransactionTagData: Object;
};

const Home = ({
  createTransaction,
  createAccount,
  formDetailsData,
  createTransactionTag,
  createTransactionTagData,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tour, setTour] = useState<boolean>(false);
  const [isCreateTransactModalVisible, setIsCreateTransactModalVisible] =
    useState(false);
  const [
    isCreateInterTransactModalVisible,
    setIsCreateInterTransactModalVisible,
  ] = useState(false);
  const [form] = Form.useForm();
  const user = getUser();
  const [prefix, setPrefix] = useState("MYR");
  const { data: accountSummariesData, loading: accountSummariesLoading } =
    useQuery(queries.accountSummaries);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  return (
    <HomeWrapper>
      <SEO title="Home" />
      <PageHeader
        title="Total Assets"
        statisticTitle={{
          first: "Net Assets",
          second: "Liabilities",
          third: "Gross Assets",
          firstAmt:
            accountSummariesData?.accountSummaries?.data?.netAsset?.[
              prefix.toLowerCase()
            ],
          secondAmt:
            accountSummariesData?.accountSummaries?.data?.liabilities?.[
              prefix.toLowerCase()
            ],
          thirdAmt:
            accountSummariesData?.accountSummaries?.data?.grossAsset?.[
              prefix.toLowerCase()
            ],
        }}
        prefix={prefix}
        handleCreateTransaction={() => setIsCreateTransactModalVisible(true)}
        handleCreateInterTransaction={() =>
          setIsCreateInterTransactModalVisible(true)
        }
      />
      <div className="mx-5 mt-4" ref={ref2}>
        <Radio.Group
          defaultValue="MYR"
          buttonStyle="solid"
          onChange={res => {
            console.log(res.target.value);
            setPrefix(res.target.value);
          }}
        >
          <Radio.Button value="MYR">MYR</Radio.Button>
          <Radio.Button value="SGD">SGD</Radio.Button>
          <Radio.Button value="USD">USD</Radio.Button>
        </Radio.Group>
        {/* <Button type="link" onClick={() => navigate(URLS.recurrent)}>
          Recurrent Transactions
        </Button> */}
      </div>

      {/* <Divider orientation="left">All Accounts</Divider> */}
      <div className="mx-4">
        {/* <Button
          ref={ref1}
          type="primary"
          block
          className="my-4"
          icon={<PlusCircleOutlined className="!inline-grid" />}
          onClick={() => setIsModalVisible(!isModalVisible)}
        >
          Create Account
        </Button> */}
        <Tabs defaultActiveKey="debit" className="mt-4">
          <Tabs.Tab title="Debit" key="debit">
            <List
              loading={false}
              itemLayout="horizontal"
              dataSource={
                accountSummariesData?.accountSummaries?.data?.account_summary
              }
              renderItem={attributes => {
                if (attributes.account_type.id === 2) {
                  return (
                    <List.Item
                      className="cursor-pointer"
                      onClick={() => {
                        console.log(attributes.id);
                        navigate(`${URLS.accounts}/${attributes.id}`);
                      }}
                    >
                      <Skeleton
                        avatar
                        title={false}
                        loading={accountSummariesLoading}
                        active
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar>{getFirstLetter(attributes.name)}</Avatar>
                          }
                          title={
                            <>
                              {attributes.name} ({attributes.card_number}),{" "}
                              {attributes.currency.name.toUpperCase()}
                            </>
                          }
                          description={attributes.asset_type.name}
                        />
                        <div>
                          {prefix} {attributes.balance?.[prefix.toLowerCase()]}
                        </div>
                      </Skeleton>
                    </List.Item>
                  );
                }
              }}
            />
          </Tabs.Tab>
          <Tabs.Tab title="Credit" key="credit">
            <List
              loading={false}
              itemLayout="horizontal"
              dataSource={
                accountSummariesData?.accountSummaries?.data?.account_summary
              }
              renderItem={attributes => {
                if (attributes.account_type.id === 1) {
                  return (
                    <List.Item
                      className="cursor-pointer"
                      onClick={() => {
                        console.log(attributes.id);
                        navigate(`${URLS.accounts}/${attributes.id}`);
                      }}
                    >
                      <Skeleton
                        avatar
                        title={false}
                        loading={accountSummariesLoading}
                        active
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar>{getFirstLetter(attributes.name)}</Avatar>
                          }
                          title={attributes.name}
                          description={attributes.asset_type.name}
                        />
                        <div>
                          <div>
                            {prefix}{" "}
                            {attributes.balance?.[prefix.toLowerCase()]}
                          </div>
                        </div>
                      </Skeleton>
                    </List.Item>
                  );
                }
              }}
            />
          </Tabs.Tab>
          <Tabs.Tab title="Loan" key="loan">
            <List
              loading={false}
              itemLayout="horizontal"
              dataSource={
                accountSummariesData?.accountSummaries?.data?.account_summary
              }
              renderItem={attributes => {
                if (attributes.account_type.id === 3) {
                  return (
                    <List.Item
                      className="cursor-pointer"
                      onClick={() => {
                        console.log(attributes.id);
                        navigate(`${URLS.accounts}/${attributes.id}`);
                      }}
                    >
                      <Skeleton
                        avatar
                        title={false}
                        loading={accountSummariesLoading}
                        active
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar>{getFirstLetter(attributes.name)}</Avatar>
                          }
                          title={attributes.name}
                          description={attributes.asset_type.name}
                        />
                        <div>
                          <div>
                            {prefix}{" "}
                            {attributes.balance?.[prefix.toLowerCase()]}
                          </div>
                        </div>
                      </Skeleton>
                    </List.Item>
                  );
                }
              }}
            />
          </Tabs.Tab>
        </Tabs>
      </div>
      {/* <div className="mx-5" ref={ref3}>
        <Collapse
          defaultActiveKey={["1", "2", "3"]}
          onChange={(key: string | string[]) => {
            console.log(key);
          }}
        >
          <Collapse.Panel header="Debits(Assets)" key="1">
            <List
              loading={false}
              itemLayout="horizontal"
              dataSource={
                accountSummariesData?.accountSummaries?.data?.account_summary
              }
              renderItem={attributes => {
                if (attributes.account_type.id === 2) {
                  return (
                    <List.Item
                      className="cursor-pointer"
                      onClick={() => {
                        console.log(attributes.id);
                        navigate(`${URLS.accounts}/${attributes.id}`);
                      }}
                    >
                      <Skeleton
                        avatar
                        title={false}
                        loading={accountSummariesLoading}
                        active
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar>{getFirstLetter(attributes.name)}</Avatar>
                          }
                          title={
                            <>
                              {attributes.name} ({attributes.card_number}),{" "}
                              {attributes.currency.name.toUpperCase()}
                            </>
                          }
                          description={attributes.asset_type.name}
                        />
                        <div>
                          {prefix} {attributes.balance?.[prefix.toLowerCase()]}
                        </div>
                      </Skeleton>
                    </List.Item>
                  );
                }
              }}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Credits(Liabilities)" key="2">
            <List
              loading={false}
              itemLayout="horizontal"
              dataSource={
                accountSummariesData?.accountSummaries?.data?.account_summary
              }
              renderItem={attributes => {
                if (attributes.account_type.id === 1) {
                  return (
                    <List.Item
                      className="cursor-pointer"
                      onClick={() => {
                        console.log(attributes.id);
                        navigate(`${URLS.accounts}/${attributes.id}`);
                      }}
                    >
                      <Skeleton
                        avatar
                        title={false}
                        loading={accountSummariesLoading}
                        active
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar>{getFirstLetter(attributes.name)}</Avatar>
                          }
                          title={attributes.name}
                          description={attributes.asset_type.name}
                        />
                        <div>
                          <div>
                            {prefix}{" "}
                            {attributes.balance?.[prefix.toLowerCase()]}
                          </div>
                        </div>
                      </Skeleton>
                    </List.Item>
                  );
                }
              }}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Loan(Liabilities)" key="3">
            <List
              loading={false}
              itemLayout="horizontal"
              dataSource={
                accountSummariesData?.accountSummaries?.data?.account_summary
              }
              renderItem={attributes => {
                if (attributes.account_type.id === 3) {
                  return (
                    <List.Item
                      className="cursor-pointer"
                      onClick={() => {
                        console.log(attributes.id);
                        navigate(`${URLS.accounts}/${attributes.id}`);
                      }}
                    >
                      <Skeleton
                        avatar
                        title={false}
                        loading={accountSummariesLoading}
                        active
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar>{getFirstLetter(attributes.name)}</Avatar>
                          }
                          title={attributes.name}
                          description={attributes.asset_type.name}
                        />
                        <div>
                          <div>
                            {prefix}{" "}
                            {attributes.balance?.[prefix.toLowerCase()]}
                          </div>
                        </div>
                      </Skeleton>
                    </List.Item>
                  );
                }
              }}
            />
          </Collapse.Panel>
        </Collapse>
        <Button
          ref={ref1}
          type="primary"
          block
          className="my-8"
          icon={<PlusCircleOutlined className="!inline-grid" />}
          onClick={() => setIsModalVisible(!isModalVisible)}
        >
          Create Account
        </Button>
      </div> */}
      <CreateAccountModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        formDetailsData={formDetailsData}
        onHandleFormSubmit={values => {
          values.user = user?.id;
          values.init_bal = parseFloat(values.init_bal);
          if (values.credit_limit)
            values.credit_limit = parseFloat(values.credit_limit);
          createAccount({
            variables: {
              data: values,
            },
          });
          form.resetFields();
          setIsModalVisible(false);
        }}
      />
      <CreateTransactionModal
        isModalVisible={isCreateTransactModalVisible}
        setIsModalVisible={setIsCreateTransactModalVisible}
        formDetailsData={formDetailsData}
        onHandleFormSubmit={(values, form) => {
          values.user = user?.id;
          values.amount = Number(values.amount);

          createTransaction({
            variables: {
              data: values,
            },
          });
          form.resetFields();
          setIsCreateTransactModalVisible(false);
        }}
        createTransactionTagData={createTransactionTagData}
        onHandleAddTag={name => {
          console.log(name);

          createTransactionTag({
            variables: {
              data: {
                name,
                user: user?.id,
              },
            },
          });
        }}
      />
      <CreateInterTransactionModal
        isModalVisible={isCreateInterTransactModalVisible}
        setIsModalVisible={setIsCreateInterTransactModalVisible}
        formDetailsData={formDetailsData}
        onHandleFormSubmit={(values, form) => {
          console.log("Received values of form: ", values);
          createTransaction({
            variables: {
              data: {
                amount: Number(values.transfer_amount),
                transaction_type: 2,
                account: values.from_account,
                date_time: values.date_time,
                transaction_tag: 3,
                user: user?.id,
              },
            },
          });
          createTransaction({
            variables: {
              data: {
                amount: Number(values.transfer_amount),
                transaction_type: 1,
                account: values.to_account,
                date_time: values.date_time,
                transaction_tag: 3,
                user: user?.id,
              },
            },
          });
          form.resetFields();
          setIsCreateInterTransactModalVisible(false);
        }}
      />
      <Tour
        open={tour}
        onClose={() => setTour(false)}
        steps={[
          {
            title: "Upload File",
            description: "Put your files here.",
            cover: (
              <img
                alt="tour.png"
                src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png"
              />
            ),
            target: () => ref1.current,
          },
          {
            title: "Save",
            description: "Save your changes.",
            target: () => ref2.current,
          },
          {
            title: "Other Actions",
            description: "Click to see other actions.",
            target: () => ref3.current,
          },
        ]}
      />
    </HomeWrapper>
  );
};

export default withMutateTransactionTag(
  withMutateAccount(
    withQueryFormDetails(withMutateTransact(withLocation(Home)))
  )
);

const HomeWrapper = styled.div`
  && {
    margin: 0 auto;
  }
  .ant-list-item-meta-title {
    margin-top: 0;
  }
`;
