import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { grapqhURL } from "@helpers/configs";
import { appDetails, URLS } from "@helpers/const";
import { getToken } from "@services/auth";

import { createResolvers } from "./resolvers";

const httpLink = new HttpLink({
  uri: grapqhURL,
});

const authLink = setContext(async (_, { headers, ...context }) => {
  const token = getToken();
  return {
    ...context,
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      const hasAuthError = graphQLErrors.some(
        error =>
          error.extensions?.code === "UNAUTHENTICATED" ||
          error.extensions?.code === "FORBIDDEN" ||
          error.message.toLowerCase().includes("jwt") ||
          error.message.toLowerCase().includes("token") ||
          error.message.toLowerCase().includes("unauthorized")
      );

      if (hasAuthError && typeof window !== "undefined") {
        localStorage.removeItem(`${appDetails.name}_token`);
        return;
      }
    }

    if (networkError) {
      const networkErr = networkError;
      const status = networkErr?.response?.status || networkErr?.statusCode;

      if (status === 401 || status === 403) {
        if (typeof window !== "undefined") {
          localStorage.removeItem(`${appDetails.name}_token`);
          window.location.href = URLS.login;
          return;
        }
      }
      console.log(`[Network error]: ${networkError}`);
    }

    return forward(operation);
  }
);

const retryLink = new RetryLink({
  attempts: {
    max: 2,
    retryIf: (error, _operation) => {
      const hasAuthError = error?.graphQLErrors?.some(
        err =>
          err.extensions?.code === "UNAUTHENTICATED" ||
          err.extensions?.code === "FORBIDDEN" ||
          err.message.toLowerCase().includes("jwt") ||
          err.message.toLowerCase().includes("token") ||
          err.message.toLowerCase().includes("unauthorized")
      );
      return !hasAuthError && !!error;
    },
  },
  delay: {
    initial: 300,
    jitter: true,
    max: 3000,
  },
});

export const apollo = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink), retryLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          transactions: {
            keyArgs: false,
            merge(existing, incoming) {
              let launches = [];
              if (existing && existing.launches) {
                launches = launches.concat(existing.launches);
              }
              if (incoming && incoming.launches) {
                launches = launches.concat(incoming.launches);
              }
              return {
                ...incoming,
                launches,
              };
            },
          },
        },
      },
    },
  }),
  resolvers: createResolvers(),
});
