import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

type TransactionContextType = {
  isCreateTransactModalVisible: boolean;
  setIsCreateTransactModalVisible: (value: boolean) => void;
  isCreateInterTransactModalVisible: boolean;
  setIsCreateInterTransactModalVisible: (value: boolean) => void;
};

type ProviderProps = {
  children: React.ReactNode;
};

const TransactionContext = createContext<TransactionContextType | undefined>(
  undefined
);

export const TransactionProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isCreateTransactModalVisible, setIsCreateTransactModalVisible] =
    useState(false);
  const [
    isCreateInterTransactModalVisible,
    setIsCreateInterTransactModalVisible,
  ] = useState(false);

  return (
    <TransactionContext.Provider
      value={{
        isCreateTransactModalVisible,
        setIsCreateTransactModalVisible,
        isCreateInterTransactModalVisible,
        setIsCreateInterTransactModalVisible,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

TransactionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTransactionContext = () => {
  const context = useContext(TransactionContext);
  if (context === undefined) {
    throw new Error(
      "useTransactionContext must be used within a TransactionProvider"
    );
  }
  return context;
};
